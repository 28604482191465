const theme = {
  color: {
    main01: '#19297C', // navy
    main02: '#4036ED', // blue
    main03: '#A0DDFF', // sky blue
    main04: '#FFAA29', // orange
    main05: '#FFFFFF', // white

    // blue scale
    secondary01: '#32439C',
    secondary02: '#5B6BBE',
    secondary03: '#8896DF',
    secondary04: '#B1BEFF',

    grayscale01: '#1B1D1F', // black
    grayscale02: '#26282B',
    grayscale03: '#464C52',
    grayscale04: '#9FA4A9',
    grayscale05: '#CACDD2',
    grayscale06: '#E9EBED',
    grayscale07: '#F7F8F9', // light gray
  },
  text: {
    h1: { fontSize: '4.5rem', fontWeight: 700 },
    h2: { fontSize: '3rem', fontWeight: 700 },
    h3: { fontSize: '2.25rem', fontWeight: 700 },
    h4: { fontSize: '1.5rem', fontWeight: 700 },
    h5: { fontSize: '1.25rem', fontWeight: 700 },
    paragraph: { fontSize: '1.25rem', fontWeight: 400 },
    small: { fontSize: '1rem', fontWeight: 400 },
    button: { fontSize: '1.25rem', fontWeight: 700 },
    nav: { fontSize: '1.5rem', fontWeight: 400 },
  },
};
export default theme;
